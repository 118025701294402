import React from 'react'

import { saveObj }  from '../../store/api.js'

import InputText    from '../_atoms/InputText.jsx'
import Label        from '../_atoms/Label.jsx'
import Helper       from '../../config/helper.js'

export default class FormMesa extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model:this.props.model,
            id:this.props.id,
            data:{
              items:{},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async componentDidMount() { }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, "StockProduto")
          Helper.addNotification('success', 'Sucesso', 'Registro excluido com sucesso', 'top-right')
          window.history.back()
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value
            data.items['tipo'] = 'entrada'
            data.items['id_produto'] = this.props.id

            this.setState({ data })
        }
    }
    
    render() {
        const atual = this.state.data.items
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <h3>Criar nova mesa ou comanda</h3>
                    <p>Aqui você pode criar as mesas ou comanda (cartão) se criará, um QR Code para que o cliente possa realizar seus pedidos ou apenas visualizar o cardápio.</p>
                </div>
                <div className='row'>
                    <div className="col-md-6">
                    
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="quantidade"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.quantidade : ""}
                            />
                            <Label nome="quantidade" label="Entrar quantidade"/>
                        </div>
                        
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}